<template>
  <b-row>
    <b-col cols="12">
      <b-card title="All Programs">

        <!-- search input -->
        <div class="custom-search d-flex justify-content-start">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search"
                type="text"
              />
              <label class="mr-1">Avarage cumulative Score of all Partners: <b>{{ avgScore }}</b></label>
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'fullName'"
              class="text-nowrap"
            >
              <b-avatar
                :src="props.row.avatar"
                class="mx-1"
              />
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  dropright
                  no-caret
                  size="sm"
                  toggle-class="text-decoration-none"
                  variant="link"
                >
                  <template v-slot:button-content>
                    <feather-icon
                      class="text-body align-middle"
                      icon="MoreVerticalIcon"
                      size="16"
                    />
                  </template>
                  <b-dropdown-item :to="`/programs/viewIndividualScoring/${$route.params.apid}/${$route.params.aid}/${props.row.id}`">
                    <feather-icon
                      class="mr-50"
                      icon="EyeIcon"
                    />
                    <span>View Individual Scoring</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  align="right"
                  class="mt-1 mb-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-col>
    <!-- Ind/Limited Res. modal -->
    <b-modal
      id="unitResource"
      hide-header-close
      ok-only
      title="Basic Modal"
    >
      <b-card-text>
        <i>Total Units Available:</i> 12<br>
        <i>Total Units Availed by Startups:</i> 3 <br>
        <span class="font-medium-2 text-primary">Grand Total: 15 <br></span>
      </b-card-text>
    </b-modal>
    <b-modal
      id="sharedResource"
      hide-header-close
      ok-only
      title="Basic Modal"
    >
      <b-card-text>
        Shared Resource Booking.
      </b-card-text>
    </b-modal>
  </b-row>
</template>

<script>
/* eslint-disable */
import {
  BModal,
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import gql from 'graphql-tag'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  data() {
    return {
      avgScore:0,
      pageLength: 5,
      dir: false,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Partners',
          field: 'designation',
        },
        {
          label: 'Total Score',
          field(row) {
            if (!row.programs_applicationdirectscores.length) return '-'
            return row.programs_applicationdirectscores.reduce((total, obj) => obj.score * obj.programs_applicationresponsetable.programs_applicationsquestionstable.multiplier + total, 0)
          },
        },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  watch: {
    rows(newValue, oldValue) {
      if (newValue !== oldValue) {
        for(let i = 0; i < this.rows.length; i += 1) {
          for(let j = 0; j < this.rows[i].programs_applicationdirectscores.length; j += 1) {
            if (this.rows[i].programs_applicationdirectscores[j].programs_applicationresponsetable.programs_applicationsquestionstable.multiplier === null) {
              this.rows[i].programs_applicationdirectscores[j].programs_applicationresponsetable.programs_applicationsquestionstable.multiplier = 1
            }
          }
          this.avgScore += this.rows[i].programs_applicationdirectscores.reduce((total, obj) => obj.score * obj.programs_applicationresponsetable.programs_applicationsquestionstable.multiplier + total, 0)
        }
      }
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  apollo: {
    rows: {
      query(){
      return gql`
        query($aid: Int!, $apid: Int!)
        {
          programs_partnerstable(where: {programs_applicationdirectscores: {programs_applicationresponsetable: {applicant_id: {_eq: $aid}, programs_applicationsquestionstable: {application_form_id: {_eq: $apid}}}}}) {
            id
            designation
            programs_applicationdirectscores {
              score
              programs_applicationresponsetable {
                programs_applicationsquestionstable {
                  multiplier
                }
              }
            }
          }
        }`
        },
      variables() {
        return {
          aid: parseInt(this.$route.params.aid),
          apid: parseInt(this.$route.params.apid)
        }
      },
      update: data => data.programs_partnerstable,
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
